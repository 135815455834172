/* GADOT custom added css */

@page {
  margin-top: 20mm;
  margin-bottom: 20mm;
  margin-right: 15mm;
  margin-left: 30mm;
}

.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  display: flex;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.p-rowgroup-header {
  background: #f0f8ff !important;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.customradiocontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  font-size: 16px;
}

.customradiocontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.customradiocontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

.customradiocontainer input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.customradiocontainer input:checked ~ .checkmark:after {
  display: block;
}

.customradiocontainer .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* loader */
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loader */

/* GADOT custom added css */
.main-mail-subject strong {
  display: block;
}

.main-mail-checkbox {
  font-size: 25px;
  display: block;
}

.main-mail-list {
  scroll-behavior: smooth;
}

.main-mail-list::-webkit-scrollbar {
  width: 8px;
}

.search-form {
  height: 25px;
  width: 250px;
}

.main-content-title {
  font-size: 20px;
}

.dark-theme .main-mail-item:hover,
.dark-theme .main-mail-item:focus {
  background-color: rgba(255, 255, 255, 0.295);
}

.dark-theme
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #f6f5ff;
  background: #1d2244;
}

.dark-theme .ant-input[disabled] {
  color: #f6f5ff;
  background: #1d2244;
}

.dark-theme .ant-upload-list-text .ant-upload-text-icon .anticon {
  color: white;
}

.dark-theme .ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  color: white;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: black;
}

.ant-input[disabled] {
  color: black;
}

.dark-theme .ant-select {
  color: white;
}

.dark-theme .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #1d2244;
}

.dark-theme .ant-select-arrow {
  color: white;
}

.dark-theme .ant-input {
  background-color: #1d2244;
  color: white;
}

.dark-theme .ant-upload.ant-upload-drag {
  background: #1d2244;
  border: 1px dashed #ffffff;
}

.dark-theme .ant-upload.ant-upload-drag p.ant-upload-text {
  color: white;
}

.dark-theme .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: white;
}

.dark-theme .ant-upload-list-item-card-actions .anticon {
  color: white;
}

.dark-theme .main-mail-item {
  border-top: none;
  border-bottom: none;
}

.dark-theme .develop-border-selected {
  background-color: #353b61;
}

.dark-theme .main-mail-item.unread {
  background-color: #353b61;
}

.dark-theme .develop-border {
  background: #353b61 !important;
}

.dark-theme .ant-upload-list-item-info:hover {
  background: #ffffff70;
}

.icon-circle {
  border-radius: 50%;
  line-height: 1.5;
  height: 30px;
  width: 30px;
  background: rgb(251 251 252);
  border: 1px solid #e0e0f3 !important;
  margin-right: 5px;
  padding-left: 2.5px;
  padding-top: 7px;
}

/* status colors */

.doc-status-develop {
  color: #1c84c6 !important;
}

.doc-status-success {
  color: #039b24 !important;
}

.doc-status-waiting {
  color: rgb(137, 92, 209) !important;
}

.doc-status-check {
  color: rgba(91, 25, 145, 0.836) !important;
}

.doc-status-info {
  color: #23c6c8 !important;
}

.dark-theme .hh1 {
  color: white;
}

.doc-status-lawyer {
  color: #bb6c18 !important;
}

.doc-status-waring {
  color: #f0ad4e !important;
}

.doc-status-danger {
  color: #bb2124 !important;
}

.doc-status-new {
  color: #4ac6c9 !important;
}

.doc-status-read {
  color: #00b3ff !important;
}

.develop-selected-fa {
  float: right;
  color: green;
  margin-right: 10px;
  margin-top: 10px;
}

/* status colors */

.main-nav-column .nav-link.active {
  font-weight: 500;
}

.dark-theme .main-nav-column .nav-link:hover {
  background: #7e7e7e;
  border-radius: 5px;
}

.main-nav-column .nav-link:hover {
  background: #dedcfb;
  border-radius: 5px;
}

.main-mail-menu .nav-link i {
  font-size: 18px;
}

.main-mail-header {
  margin-bottom: 15px;
}

.main-mail-header .btn-group .btn,
.main-mail-header .btn-group .sp-container button {
  width: 30px;
  height: 27px;
}

.main-mail-list {
  height: calc(100vh - 305px);
  overflow: auto;
}

.empty-list {
  margin-top: 50px;
  margin-bottom: 50px;
}

.connect-worker-select {
  height: 26px;
  padding: 0px 0px 2px 5px;
}

.dev-table-middle {
  vertical-align: middle !important;
}

.develop-worker {
  flex-shrink: 0;
  margin-right: 15px;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 60px;
  border-radius: 100%;
  text-align: center;
}

.develop-worker img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 100%;
}

.develop-card-header {
  padding: 0px 0px;
  border-bottom: none;
  border-top: none;
}

.develop-card-body {
  font-size: 14px;
}

.develop-card-subject {
  white-space: normal;
  margin-bottom: 5px;
}

.develop-card-body1 {
  cursor: auto;
  max-width: 100% !important;
}

.develop-card-body12 {
  max-width: 100% !important;
}

.develop-body2 {
  margin-top: 15px;
}

.develop-border-selected {
  border: 1px solid #0ba360 !important;
  margin-bottom: 1.3rem;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1;
  color: red;
}

.accordion .card-header a {
  background: linear-gradient(45deg, #569b2f, #3858f9);
  color: white;
  cursor: context-menu;
}

.accordion .card-header a.collapsed {
  color: white;
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
  color: #ffffff !important;
}

.accordion .card-header a:hover,
.accordion .card-header a:focus {
  color: white !important;
}

.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 12px;
}

.ant-select-tree-switcher {
  width: 22px;
  line-height: 18px;
}

.ant-tree-select-dropdown {
  padding: 2px 2px 0px 0px;
}

.ant-tree-select-dropdown
  .ant-select-tree-list-holder-inner
  .ant-select-tree-treenode {
  padding-bottom: 4px;
  padding-top: 3px;
}

.dark-theme .ant-input-number-input {
  background-color: #1d2244 !important;
}

.dark-theme .ant-input-number-disabled {
  color: white;
}

.dark-theme .ant-input-number {
  color: white;
}

.main-mail-subject strong {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dark-theme .ant-table {
  background: #2b3054 !important;
  color: white !important;
}

.dark-theme .ant-table-thead > tr > th {
  background: #2b3054 !important;
  color: white !important;
}

.dark-theme .ant-table-row-expand-icon {
  background: #2b3054 !important;
  color: white !important;
}

.dark-theme .ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(128, 128, 128, 0.5);
}

.ant-table {
  background: #fbfaff;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #f6f5ff;
}

.table-row-vertical {
  vertical-align: middle !important;
}

.card-background {
  background: linear-gradient(45deg, rgb(86, 155, 47), rgb(56, 88, 249));
  color: white;
}

.doc-sign {
  padding-bottom: 15px;
}

.btn-color-return {
  background-color: #6e7d88 !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.btn-color-accept {
  background-color: #7367f0 !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.app-sidebar .side-badge {
  right: 11px;
  top: 11px;
}

.side-menu__label {
  font-size: 12px;
}

.card-title {
  font-weight: 600;
}

/* 404 */

#notfound .notfound {
  position: relative;
}

.notfound {
  text-align: center;
}

.notfound h2 {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound a {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
  box-shadow: 0px 4px 15px -5px #0046d5;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 142px;
  }

  .notfound .notfound-404 h1 {
    font-size: 100px;
  }
}

.main-content-body-profile .nav {
  padding-bottom: 19px;
}

.main-nav-line .nav-link {
  padding: 7px 17px 5px 10px;
}

.tr-color-me {
  background-color: #0ba36029 !important;
}

.badge {
  padding: 5px 5px 6px !important;
}

.gadot-badge-new {
  background-color: rgb(74, 198, 201);
}

.gadot-badge-expire {
  background-color: rgb(187, 33, 36);
}

.gadot-badge-waring {
  background-color: rgb(240, 173, 78);
}

.gadot-badge-viewed {
  background-color: rgb(0, 179, 255);
}

.gadot-badge-working {
  background-color: #1a31a5;
  color: white;
}

.gadot-badge-dist {
  background-color: #7987a1;
  color: white;
}

.gadot-badge-finished {
  background-color: rgb(3, 155, 36);
}

.gadot-badge-success {
  background-color: rgb(3, 155, 36);
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  margin-right: 8px;
}

.mission-cost {
  position: absolute;
  right: 0;
  bottom: auto;
  top: 28px;
  margin-right: 15px;
}

.mission-cost-strong {
  font-weight: 600;
  font-size: 14px;
  color: #3c3e5d;
}

/* checkbox */
.gadot-checkbox {
  width: 16px !important;
  height: 16px !important;
  margin-top: 3px !important;
}

.ckbox span:before {
  top: 4px !important;
  border: 1px solid #dedcfb !important;
}

.ckbox span:after {
  top: 4px !important;
}

/* checkbox */
.wizard-none {
  border: none !important;
  border-right: 1px solid #dedcfb !important;
  border-left: 1px solid #dedcfb !important;
}

.main-mail-header > div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-mail-header > div:last-child > span {
  font-weight: bold;
  margin-left: 10px;
  width: 40px;
}

._loading_overlay_wrapper {
  position: unset !important;
}

.newyear-logo {
  height: 2rem;
  position: absolute;
  right: 40px;
  top: 11px;
}

.newyear-logo-mobile {
  height: 2rem;
  position: absolute;
  top: 4px;
  left: 64px;
}

.main-mail-options {
  padding-left: 25px;
  display: flex;
  justify-content: space-between;
}

.main-mail-item {
  padding: 10px 10px;
}

@media only screen and (min-width: 992px) {
  .main-mail-item {
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 576px) {
  .main-mail-item {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .civil-header-top {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .civil-header-top1 {
    padding-top: 15px;
  }
}

.civil-padding-top {
  padding-top: 15px;
}

.dashboard-doc-type {
  border-bottom: 2px solid #0ba360 !important;
}

.main-nav-column .nav-link span {
  font-weight: 700 !important;
}

.main-mail-subject span {
  display: block !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.main-mail-menu .nav-link {
  font-size: 12px !important;
}

.img_color52 {
  color: #4ac6c9;
}

.img_color76 {
  color: #00b3ff;
}

.img_color56 {
  color: #1a31a5;
}

.img_color54 {
  color: #f0ad4e;
}

.img_color57 {
  color: #039b24;
}

.img_color75 {
  color: #039b24;
}

.img_color55 {
  color: #bb2124;
}

.img_color86 {
  color: #bb2124;
}

.img_color29 {
  color: #1c84c6;
}

.img_color30 {
  color: #039b24;
}

.static-height {
  height: 520px;
}

.child-active {
  border-bottom: 2px solid #0ba360;
}

.country-table .table th,
.table td {
  padding: 4px 4px;
  line-height: 1.4;
}

.projects-stat .table th,
.projects-stat .table td {
  vertical-align: middle;
}

.ant-table-cell a {
  white-space: nowrap;
}

.dark-theme .ant-table-tbody {
  background-color: #2b3054;
}

.finish-date {
  position: absolute;
  font-size: 11px;
  top: 53px;
  right: 15px;
  color: #9c96c1;
  margin-left: auto;
}

.main-mail-star {
  margin-right: 20px;
  font-size: 15px;
  line-height: 0.9;
  color: #606060;
  position: absolute;
  right: -4px;
  bottom: auto;
  top: 33px;
}

.paperclip {
  margin-right: 46px !important;
}

/* table-responsive */
.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
  width: 12px;
}

.table-responsive::-webkit-scrollbar:horizontal {
  height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.gadot-card-header {
  background: linear-gradient(45deg, #569b2f, #3858f9);
  display: flex;
  flex-direction: row;
  color: white;
  cursor: context-menu;
  padding: 14px 20px !important;
  align-items: center;
}

/* table-responsive */
.gadot-card-body {
  padding: 0px !important;
}

.gadot-check {
  margin-right: 15px;
}

.mail-approve {
  padding-left: 15px;
}

.developer-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.developer-form-select1 {
  width: 20%;
}

.developer-form-select2 {
  width: 50%;
}

.developer-form-select3 {
  width: 80%;
}

.my-develop-card-header {
  background-color: #607d8b;
  color: #f2f2f2;
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 35px;
  padding-left: 1rem;
}

.my-develop-card-header-title {
  font-size: 14px;
  font-weight: 500;
}

.my-gadot-card-body {
  padding-top: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.my-gadot-card-body2 {
  padding-top: 0px;
}

.wizard > .content {
  border-bottom: none;
  padding: 0;
}

.component-card {
  margin-bottom: 0;
  margin-top: 1rem;
}

.wizard {
  margin-top: 10px;
}

.wizard > .steps {
  padding: 15px;
}

.develop-text-value {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

.develop-text-value:hover {
  background-color: rgba(199, 199, 199, 0.384);
  transition: 0.5s;
  border-radius: 2px;
}

.attach-hover:hover {
  background-color: rgba(199, 199, 199, 0.384);
  transition: 0.5s;
  border-radius: 2px;
}

.main-header.side-header.fixed-header {
  z-index: 87 !important;
}

.app-sidebar {
  z-index: 88 !important;
}

.tab-container {
  display: flex;
  flex-direction: row;
  margin: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  overflow-x: auto;
}

.tab-item {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 50px;
  padding-right: 50px;
  border-right: 1px solid #e5e7eb;
  color: #707070;
  font-weight: 600;
  cursor: pointer;
}

.dark-theme .tab-item {
  color: white;
}

.tab-item:hover {
  background-color: #f3f4f6;
  color: #303030;
}

.tab-item.active {
  color: #3858f9;
  background-color: #f3f4f6;
  font-size: 14.5px;
}

.list-container {
  border-right: 1px solid #dedcfb;
  border-left: 1px solid #dedcfb;
  border-bottom: 1px solid #dedcfb;
  padding: 5px 15px 5px 20px;
}

.ant-tree-switcher {
  line-height: 17px !important;
}

.ant-select-multiple .ant-select-selection-item-remove {
  line-height: 1.6 !important;
}

.ant-select-clear {
  margin-top: -9px !important;
}

@media only screen and (max-width: 1250px) {
  .developer-form-select1 {
    width: 25%;
  }

  .developer-form-select2 {
    width: 60%;
  }

  .developer-form-select3 {
    width: 75%;
  }
}

@media only screen and (max-width: 1000px) {
  .developer-form-select1 {
    width: 25%;
  }

  .developer-form-select2 {
    width: 75%;
  }

  .developer-form-select3 {
    width: 75%;
  }
}

@media only screen and (max-width: 510px) {
  .developer-form-container {
    display: block;
  }

  .developer-form-select1 {
    width: 100%;
  }

  .developer-form-select2 {
    width: 100%;
  }

  .developer-form-select3 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .paperclip {
    margin-right: 46px !important;
  }

  .main-mail-star {
    margin-right: 20px;
    font-size: 17px;
    line-height: 0.9;
    color: #606060;
    position: absolute;
    right: -4px;
    bottom: auto;
    top: 32px;
  }

  .finish-date {
    position: absolute;
    font-size: 13px;
    top: 53px;
    right: 15px;
    color: #9c96c1;
    margin-left: auto;
  }
}

@media only screen and (max-width: 1180px) {
  .wizard > .steps > ul li + li {
    margin-left: 5px;
  }

  .wizard > .steps a .number,
  .wizard > .steps a:hover .number,
  .wizard > .steps a:active .number {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
    width: 30px;
    height: 30px;
  }

  .wizard > .steps a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 920px) {
  .wizard > .steps > ul li .title {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .my-gadot-card-body {
    padding-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .mobile-margin {
    margin-bottom: 10px !important;
  }

  .attach-memory {
    display: none;
  }

  .attach-multi-files {
    display: none !important;
  }

  .develop-text-value {
    justify-content: space-between;
    margin-left: 0px;
    margin-top: 6px;
  }

  .wizard > .steps .disabled {
    display: block;
  }

  .wizard > .steps > ul li .title {
    display: none;
  }

  .wizard > .steps a {
    font-size: 12px;
  }

  .search-form {
    width: 150px;
    font-size: 9px;
  }

  .ant-picker-input > input {
    font-size: 9px;
  }

  .main-mail-date {
    font-size: 10px;
    top: 13px;
    right: 4px;
  }

  .finish-date {
    font-size: 10px;
    top: 57px;
    right: 5px;
  }

  .paperclip {
    margin-right: 30px !important;
  }

  .main-mail-star {
    margin-right: 9px;
    font-size: 13px;
    line-height: 0.9;
    color: #606060;
    position: absolute;
    right: -3px;
    bottom: auto;
    top: 36px;
  }

  .mail-approve {
    padding: 5px 5px 5px 10px;
  }
}

.animate-charcter {
  background-image: linear-gradient(
    -225deg,
    #569b2f 0%,
    #3858f9 29%,
    #ff1361 67%,
    #b057bf 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 24px;
  font-weight: bolder;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.loading-spinner {
  width: 30px;
  height: 30px;
  border: 2px solid indigo;
  border-radius: 50%;
  border-top-color: #0001;
  display: inline-block;
  animation: loadingspinner 0.7s linear infinite;
}
@keyframes loadingspinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dxreRuler .dxre-rulerDivisions .dxre-rulerNumberDivision > div {
  white-space: nowrap !important;
}

.dan-text {
  background-image: linear-gradient(90deg, #001d83 2.14%, #2048ec 112.04%);
}
